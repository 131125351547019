import React from "react";
import ImagePuff from "./ImagePuff";

const ImagePuffs = (props) => {
    return (
        <>
            {props.imagePuffs.map((imagePuff) => (
                <ImagePuff key={imagePuff.id} imagePuff={imagePuff} />
            ))}
        </>
    );
};

export default ImagePuffs;
