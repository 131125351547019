.puffs {
    display: grid;
    gap: 1em;
    overflow: visible;
}

@media screen and (min-width: 1200px) {
    .puffs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
    }
}
