import React from "react";
import * as classes from "./Checkbox.module.css";

const Checkbox = (props) => {
    return (
        <div>
            <label htmlFor={props.id} className={classes["label"]}>
                <input
                    className={classes["input"]}
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    type={"checkbox"}
                />
                {props.label}
            </label>
        </div>
    );
};

export default Checkbox;
