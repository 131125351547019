import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TinyMCE(props) {
    const editorRef = useRef(null);

    const initialValue = "<p>Lägg till en kort text 🙃</p>";
    const [tinyValue, setTinyValue] = useState(initialValue ?? "");

    useEffect(
        () => setTinyValue(initialValue == ""),
        [initialValue]
    );

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    return (
        <>
            <Editor
                initialValue={initialValue}
                value={tinyValue.toString()}
                onEditorChange={(newValue, editor) => {
                    setTinyValue(newValue),
                    props.textChangeHandler(editor.getContent({ format: "text" }));
                }}
                onInit={(evt, editor) => (
                    (editorRef.current = editor),
                    props.textChangeHandler(editor.getContent({ format: "text" }))
                )}
                apiKey="pzsyo6alyuxeh9kfvy58vha9xctrhqydkt7k1d62zk20lve1"
                init={{
                    height: 300,
                    menubar: false,
                    plugins: "emoticons",
                    selector: "#tinymce",
                    language: "sv_SE",
                    branding: false,
                    toolbar: "undo redo | emoticons",
                    content_style:
                        "body { font-family:sans-serif; font-size:16px }",
                }}
            />
        </>
    );
}
