import React, { useState } from "react";
import Button from "../Buttons/Button";
import useInput from "../Hooks/useInput";
import Checkbox from "../Inputs/Checkbox";
import Input from "../Inputs/Input";
import Select from "../Inputs/Select";
import TextArea from "../Inputs/TextArea";
import Card from "../Ui/Card";
import Form from "../Ui/Form";
import * as classes from "./DeclarationOfInterestForm.module.css";
import Axios from "../../assets/js/axios-instance";

const DeclarationOfInterestForm = () => {
    const isAboveTwoCharacters = (value) => value.trim().length >= 2;

    const [isChild, setIsChild] = useState(false);
    const [isAllergy, setIsAllergy] = useState(false);
    const [isOtherAnimals, setIsOtherAnimals] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [enteredResidence, setEnteredResidence] = useState("Villa");

    const isChildChangeHandler = () => {
        setIsChild(!isChild);
    };

    const isAllergyChangeHandler = () => {
        setIsAllergy(!isAllergy);
    };

    const isOtherAnimalsChangeHandler = () => {
        setIsOtherAnimals(!isOtherAnimals);
    };

    const isWorkingChangeHandler = () => {
        setIsWorking(!isWorking);
    };

    const onResidenceChangeHandler = (event) => {
        setEnteredResidence(event.target.value);
    };

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredLastName,
        isValid: enteredLastNameIsValid,
        hasError: lastNameInputHasError,
        valueChangeHandler: lastNameChangedHandler,
        inputBlurHandler: lastNameBlurHandler,
        reset: resetLastNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredAddress,
        isValid: enteredAddressIsValid,
        hasError: addressInputHasError,
        valueChangeHandler: addressChangedHandler,
        inputBlurHandler: addressBlurHandler,
        reset: resetAddressInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredCity,
        isValid: enteredCityIsValid,
        hasError: cityInputHasError,
        valueChangeHandler: cityChangedHandler,
        inputBlurHandler: cityBlurHandler,
        reset: resetCityInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredZipCode,
        isValid: enteredZipCodeIsValid,
        hasError: zipCodeInputHasError,
        valueChangeHandler: zipCodeChangedHandler,
        inputBlurHandler: zipCodeBlurHandler,
        reset: resetZipCodeInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredEmail,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmailInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredNumber,
        isValid: enteredNumberIsValid,
        hasError: numberInputHasError,
        valueChangeHandler: numberChangedHandler,
        inputBlurHandler: numberBlurHandler,
        reset: resetNumberInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredBirthDate,
        isValid: enteredBirthDateIsValid,
        hasError: birthDateInputHasError,
        valueChangeHandler: birthDateChangedHandler,
        inputBlurHandler: birthDateBlurHandler,
        reset: resetBirthDateInput,
    } = useInput();

    const {
        value: enteredFamilyStatus,
        isValid: enteredFamilyStatusIsValid,
        hasError: familyStatusInputHasError,
        valueChangeHandler: familyStatusChangedHandler,
        inputBlurHandler: familyStatusBlurHandler,
        reset: resetFamilyStatusInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredChildStatus,
        isValid: enteredChildStatusIsValid,
        hasError: childStatusInputHasError,
        valueChangeHandler: childStatusChangedHandler,
        inputBlurHandler: childStatusBlurHandler,
        reset: resetChildStatusInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredAllergyStatus,
        isValid: enteredAllergyStatusIsValid,
        hasError: allergyStatusInputHasError,
        valueChangeHandler: allergyStatusChangedHandler,
        inputBlurHandler: allergyStatusBlurHandler,
        reset: resetAllergyStatusInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredOtherAnimalStatus,
        isValid: enteredOtherAnimalStatusIsValid,
        hasError: otherAnimalStatusInputHasError,
        valueChangeHandler: otherAnimalStatusChangedHandler,
        inputBlurHandler: otherAnimalStatusBlurHandler,
        reset: resetOtherAnimalStatusInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredWorkStatus,
        isValid: enteredWorkStatusIsValid,
        hasError: workStatusInputHasError,
        valueChangeHandler: workStatusChangedHandler,
        inputBlurHandler: workStatusBlurHandler,
        reset: resetWorkStatusInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredMoreAnimals,
        isValid: enteredMoreAnimalsIsValid,
        hasError: moreAnimalsInputHasError,
        valueChangeHandler: moreAnimalsChangedHandler,
        inputBlurHandler: moreAnimalsBlurHandler,
        reset: resetMoreAnimalsInput,
    } = useInput(isAboveTwoCharacters);

    let formIsValid = false;

    if (
        enteredNameIsValid &&
        enteredLastNameIsValid &&
        enteredAddressIsValid &&
        enteredCityIsValid &&
        enteredZipCodeIsValid &&
        enteredEmailIsValid &&
        enteredNumberIsValid &&
        enteredFamilyStatusIsValid &&
        enteredChildStatusIsValid &&
        enteredAllergyStatusIsValid &&
        enteredOtherAnimalStatusIsValid &&
        enteredWorkStatusIsValid &&
        enteredMoreAnimalsIsValid &&
        enteredBirthDateIsValid
    ) {
        formIsValid = true;
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        const doiObj = {
            firstName: enteredName,
            lastName: enteredLastName,
            address: enteredAddress,
            city: enteredCity,
            zipCode: enteredZipCode,
            email: enteredEmail,
            number: enteredNumber,
            birthDate: enteredBirthDate,
            residence: enteredResidence,
            familyStatus: enteredFamilyStatus,
            isChild: isChild,
            childStatus: isChild ? enteredChildStatus : "",
            isAllergy: isAllergy,
            allergyStatus: isAllergy ? enteredAllergyStatus : "",
            isOtherAnimals: isOtherAnimals,
            otherAnimalsStatus: isOtherAnimals ? enteredOtherAnimalStatus : "",
            isWorking: isWorking,
            workingStatus: isWorking ? enteredWorkStatus : "",
            moreAnimals: enteredMoreAnimals
                ? enteredMoreAnimals
                : "<Inget Nämnt>",
        };

        console.log(doiObj);

        // Axios.post("/email/doi", doiObj)
        //     .then((reponse) => {
        //         console.log(reponse);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    };

    return (
        <Card>
            <Form onSubmit={submitHandler}>
                <Input
                    label="Förnamn"
                    input={{
                        id: "fName",
                        type: "text",
                        onChange: nameChangedHandler,
                        value: enteredName,
                        onBlur: nameBlurHandler,
                    }}
                    isValid={!nameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Efternamn"
                    input={{
                        id: "lName",
                        type: "text",
                        onChange: lastNameChangedHandler,
                        value: enteredLastName,
                        onBlur: lastNameBlurHandler,
                    }}
                    isValid={!lastNameInputHasError}
                    errorMessage="Efternamnet behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Adress"
                    input={{
                        id: "address",
                        type: "text",
                        onChange: addressChangedHandler,
                        value: enteredAddress,
                        onBlur: addressBlurHandler,
                    }}
                    isValid={!addressInputHasError}
                    errorMessage="Adressen behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Ort"
                    input={{
                        id: "city",
                        type: "text",
                        onChange: cityChangedHandler,
                        value: enteredCity,
                        onBlur: cityBlurHandler,
                    }}
                    isValid={!cityInputHasError}
                    errorMessage="Orten behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Postnummer"
                    input={{
                        id: "zipCode",
                        type: "number",
                        onChange: zipCodeChangedHandler,
                        value: enteredZipCode,
                        onBlur: zipCodeBlurHandler,
                    }}
                    isValid={!zipCodeInputHasError}
                    errorMessage="Postnummret behöver vara högre än 0"
                />

                <Input
                    label="E-Post"
                    input={{
                        id: "email",
                        type: "email",
                        onChange: emailChangedHandler,
                        value: enteredEmail,
                        onBlur: emailBlurHandler,
                    }}
                    isValid={!emailInputHasError}
                    errorMessage="Ange en giltlig E-Post adress. Ex. exempel@exempel.com"
                />

                <Input
                    label="Telefonnummer"
                    input={{
                        id: "tel",
                        type: "tel",
                        onChange: numberChangedHandler,
                        value: enteredNumber,
                        onBlur: numberBlurHandler,
                    }}
                    isValid={!numberInputHasError}
                    errorMessage="Ange ett giltligt telefonnummer"
                />

                <Input
                    label="Födelsedatum"
                    input={{
                        id: "birthDate",
                        type: "date",
                        onChange: birthDateChangedHandler,
                        value: enteredBirthDate,
                        onBlur: birthDateBlurHandler,
                    }}
                    isValid={true}
                />

                <Select
                    id={"residenceSelectAnimalType"}
                    label="Hur bor ni i dagsläget?"
                    value={enteredResidence}
                    onChange={onResidenceChangeHandler}
                >
                    <option value="Villa">Villa</option>
                    <option value="Lägenhet">Lägenhet</option>
                    <option value="Radhus">Radhus</option>
                </Select>

                <TextArea
                    label="Hur ser familjen ut?"
                    input={{
                        id: "doiFamilyStatus",
                        type: "textarea",
                        onChange: familyStatusChangedHandler,
                        value: enteredFamilyStatus,
                        onBlur: familyStatusBlurHandler,
                    }}
                    isValid={!familyStatusInputHasError}
                    errorMessage="Texten behöver innehålla minst 2 bokstäver"
                />

                <Checkbox
                    label="Har ni barn i familjen?"
                    id="doiCheckChild"
                    onChange={isChildChangeHandler}
                    value={isChild}
                    isValid={true}
                />

                {isChild && (
                    <TextArea
                        label="Ange barnens ålder."
                        input={{
                            id: "doiChild",
                            type: "text",
                            onChange: childStatusChangedHandler,
                            value: enteredChildStatus,
                            onBlur: childStatusBlurHandler,
                        }}
                        isValid={true}
                    />
                )}

                <Checkbox
                    label="Finns det allergier i familjen?"
                    id="doiCheckAllergy"
                    onChange={isAllergyChangeHandler}
                    value={isAllergy}
                    isValid={true}
                />

                {isAllergy && (
                    <TextArea
                        label="Ange allergier"
                        input={{
                            id: "doiAllergyStatus",
                            type: "text",
                            onChange: allergyStatusChangedHandler,
                            value: enteredAllergyStatus,
                            onBlur: allergyStatusBlurHandler,
                        }}
                        isValid={!allergyStatusInputHasError}
                        errorMessage="Texten behöver innehålla minst 2 bokstäver"
                    />
                )}

                <Checkbox
                    label="Finns det andra husdjur i familjen?"
                    id="doiCheckOtherAnimal"
                    onChange={isOtherAnimalsChangeHandler}
                    value={isOtherAnimals}
                    isValid={true}
                />

                {isOtherAnimals && (
                    <TextArea
                        label="Ange husdjur"
                        input={{
                            id: "doiOtherAnimalStatus",
                            type: "text",
                            onChange: otherAnimalStatusChangedHandler,
                            value: enteredOtherAnimalStatus,
                            onBlur: otherAnimalStatusBlurHandler,
                        }}
                        isValid={!otherAnimalStatusInputHasError}
                        errorMessage="Texten behöver innehålla minst 2 bokstäver"
                    />
                )}

                <Checkbox
                    label="Jobbar du/ni?"
                    id="doiCheckWork"
                    type="checkbox"
                    onChange={isWorkingChangeHandler}
                    value={isWorking}
                    isValid={true}
                />

                {isWorking && (
                    <TextArea
                        label="Vad jobbar ni med, och hur löser ni hundpassning?"
                        input={{
                            id: "doiWorkStatus",
                            type: "text",
                            onChange: workStatusChangedHandler,
                            value: enteredWorkStatus,
                            onBlur: workStatusBlurHandler,
                        }}
                        isValid={!workStatusInputHasError}
                        errorMessage="Texten behöver innehålla minst 2 bokstäver"
                    />
                )}

                <TextArea
                    label="Om intresse för flera eller andra hundar finns, skriv då hundarnas namn här."
                    input={{
                        id: "doiMoreAnimals",
                        type: "text",
                        onChange: moreAnimalsChangedHandler,
                        value: enteredMoreAnimals,
                        onBlur: moreAnimalsBlurHandler,
                    }}
                    isValid={!moreAnimalsInputHasError}
                    errorMessage="Texten behöver innehålla minst 2 bokstäver"
                />
                <div className={classes["button-wrapper"]}>
                    <Button
                        disabled={!formIsValid}
                        type="submit"
                        className={"submit"}
                    >
                        Skicka Intresseanmälan
                    </Button>
                </div>
            </Form>
        </Card>
    );
};

export default DeclarationOfInterestForm;
