import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Ui/Form";
import useInput from "../Hooks/useInput";
import Input from "../Inputs/Input";
import Button from "../Buttons/Button";
import Axios from "../../assets/js/axios-instance";
import AuthContext from "../Context/AuthContext";

const SignUpForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const authContext = useContext(AuthContext);

    const navigate = useNavigate();

    const isAboveTwoCharacters = (value) => value.trim().length >= 2;
    const isAboveSixCharacters = (value) => value.trim().length >= 6;
    const isEqualTo = (value) => value === enteredPassword;

    const {
        value: enteredUsername,
        isValid: enteredUsernameIsValid,
        hasError: usernameInputHasError,
        valueChangeHandler: usernameChangedHandler,
        inputBlurHandler: usernameBlurHandler,
        reset: resetUsernameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredPassword,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPasswordInput,
    } = useInput(isAboveSixCharacters);

    const {
        value: enteredConfirmPassword,
        isValid: enteredConfirmPasswordIsValid,
        hasError: confirmPasswordInputHasError,
        valueChangeHandler: confirmPasswordChangedHandler,
        inputBlurHandler: confirmPasswordBlurHandler,
        reset: resetConfirmPasswordInput,
    } = useInput(isEqualTo);

    let formIsValid = false;

    if (
        enteredUsernameIsValid &&
        enteredPasswordIsValid &&
        enteredConfirmPasswordIsValid
    ) {
        formIsValid = true;
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        const registerData = {
            username: enteredUsername,
            password: enteredPassword,
            confirmPassword: enteredPassword,
            isAdmin: true,
        };
        setIsLoading(true);
        await Axios.post('/user/register', registerData)
            .then(async (response) => {
                setIsLoading(false);
                const data = response;

                await Axios.post('/user/authenticate', {
                    username: enteredUsername,
                    password: enteredPassword,
                })
                    .then((response) => {
                        const data = response.data;
                        authContext.login(data.token, data.id);
                        navigate("/src");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                const data = error.response.data;
                console.log(data);
            });
    };

    return (
        <>
            <Form onSubmit={submitHandler}>
                <Input
                    label="Användarnamn"
                    input={{
                        id: "usernameInput",
                        type: "text",
                        onChange: usernameChangedHandler,
                        value: enteredUsername,
                        onBlur: usernameBlurHandler,
                    }}
                    isValid={!usernameInputHasError}
                    errorMessage="Användarnamnet behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Lösenord"
                    input={{
                        id: "passwordInput",
                        type: "password",
                        onChange: passwordChangedHandler,
                        value: enteredPassword,
                        onBlur: passwordBlurHandler,
                    }}
                    isValid={!passwordInputHasError}
                    errorMessage="Lösenordet behöver innehålla minst 6 tecken"
                />

                <Input
                    label="Bekräfta Lösenord"
                    input={{
                        id: "confirmPasswordInput",
                        type: "password",
                        onChange: confirmPasswordChangedHandler,
                        value: enteredConfirmPassword,
                        onBlur: confirmPasswordBlurHandler,
                    }}
                    isValid={!confirmPasswordInputHasError}
                    errorMessage="Lösenorden matchar inte"
                />

                <Button
                    disabled={!formIsValid}
                    type="submit"
                    className={"green"}
                >
                    Registrera
                </Button>
            </Form>
        </>
    );
};

export default SignUpForm;
