import * as classes from "./CountryGroup.module.css";
import React, { useEffect, useState } from "react";
import Card from "../Ui/Card";
import Axios from "../../assets/js/axios-instance";
import CountryForm from "./CountryForm";
import CountryList from "./CountryList"

const CountryGroup = (props) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        console.log(props.url)
    }, []);

    const postCountryHandler = async (countryName) => {
        await Axios.post('/country/create', {
            name: countryName,
        })
            .then((response) => {
                newCountryHandler(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const newCountryHandler = (newCountry) => {
        setCountries((prevCountries) => {
            return [...prevCountries, newCountry];
        });
    };

    const getCountries = async () => {
        await Axios.get('/country/all')
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Card className={classes["country-group"]}>
                <h2 className={classes["header"]}>LÄNDER</h2>
                <CountryForm onSubmit={postCountryHandler} />
                <CountryList countries={countries} />
            </Card>
        </>
    );
};

export default CountryGroup;
