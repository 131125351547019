import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as classes from "./AnimalDetailPage.module.css";
import AnimalDetail from "./AnimalDetail";
import AnimalSuggestions from "./AnimalSuggestions";
import Axios from "../../assets/js/axios-instance";

const AnimalDetailGroup = (props) => {
    const params = useParams();
    const [animal, setAnimal] = useState(null);

    const getAnimal = () => {
        Axios.get(`/animalType/name/${params.animalTypePluralName}`)
            .then((response) => {
                setAnimal(
                    response.data.animals.find(
                        (animal) => animal.id == params.animalId
                    )
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (props.animals && props.animals.length > 0) {
            setAnimal(
                props.animals.find((animal) => animal.id == params.animalId)
            );
        } else {
            getAnimal();
        }
    }, [params.animalId]);

    return (
        <>
            {animal && (
                <div className={classes["wrapper"]}>
                    <meta property="og:image" content={animal.imagePath}></meta>
                    <AnimalDetail animal={animal} />

                    {props.animals && (
                        <AnimalSuggestions
                            header={""}
                            animals={props.animals.filter(
                                (animal) => animal.id != params.animalId
                            )}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AnimalDetailGroup;
