import React from 'react'
import LargeTinyMCE from '../TinyMCE/LargeTinyMCE'

const LargeMCEInput = (props) => {
  return (
      <>
          <div className="">
              <label htmlFor={props.id}>Text</label>
              <LargeTinyMCE onChange={props.onChange} />
          </div>
      </>
  );
}

export default LargeMCEInput