import React, { useEffect, useState, useRef } from "react";
import * as classes from "./InputCarousel.module.css";
import { GoPlus } from "react-icons/go";
import Button from "../Buttons/Button";
import InputFileCarousel from "./InputFileCarousel";
import Carousel from "../Ui/Carousel";

const InputCarousel = (props) => {
    const [inputs, setInputs] = useState([]);
    const [previewObjects, setPreviewObjects] = useState([]);
    const [newPreviewObj, setNewPreviewObj] = useState(null);
    const [idToRemove, setIdToRemove] = useState(0);
    const inputCounterRef = useRef(0);

    useEffect(() => {
        if (newPreviewObj) {
            if (previewObjects.some((obj) => obj.id == newPreviewObj.id)) {
                setPreviewObjects((prevObjects) => {
                    return prevObjects.map((obj) => {
                        return obj.id == newPreviewObj.id ? newPreviewObj : obj;
                    });
                });
            } else {
                setPreviewObjects((prevImageObjects) => {
                    return [...prevImageObjects, newPreviewObj];
                });
            }
        }
    }, [newPreviewObj]);

    useEffect(() => {
        if (previewObjects.length > 0) {
            setPreviewObjects((prevImages) => {
                return prevImages.filter((image) => image.id != idToRemove);
            });
        }
    }, [idToRemove]);

    useEffect(() => {
        setInputs(() => {
            return previewObjects.map((obj) => {
                return (
                    <InputFileCarousel
                        onChange={fileChangeHandler}
                        preview={obj.src}
                        id={obj.id}
                        key={obj.id}
                        onClick={onRemoveButtonClick}
                    />
                );
            });
        });

        props.onChange(previewObjects.map((obj) => obj.file));
    }, [previewObjects]);

    const fileChangeHandler = (event) => {
        const file = event.target.files[0];

        if (file) {
            const src = URL.createObjectURL(file);

            setNewPreviewObj({
                id: event.target.id,
                src: src,
                file: file,
            });
        }
    };

    const onRemoveButtonClick = (event) => {
        setIdToRemove(event.target.id);
    };

    const onClickAddImage = () => {
        inputCounterRef.current = inputCounterRef.current + 1;

        setNewPreviewObj({
            id: inputCounterRef.current,
            src: "https://dummyimage.com/1920x1080/aaa/fff",
        });
    };

    return (
        <>
            <div>
                {props.images.length > 0 && (
                    <Carousel
                        label="Karusell"
                        images={previewObjects.map((image) => image.src)}
                    />
                )}

                <Button
                    onClick={onClickAddImage}
                    className={`${classes["carousel__button"]}`}
                >
                    <GoPlus className={classes["carousel__icon--plus"]} />
                    {"Utöka Karusell"}
                </Button>

                {props.images.length > 0 &&
                    inputs.map((imageInput, key) => {
                        return (
                            <div key={key}>
                                <div>
                                    <p>{`Bild #${key + 1}`}</p>
                                </div>
                                {imageInput}
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default InputCarousel;
