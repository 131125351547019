.puff img {
    width: 100%;
    border-radius: var(--primary-border-radius) var(--primary-border-radius) 0 0;
    display: flex;
}

.puff__content {
    display: grid;
    gap: 1em;
    padding: 1em;
}

.puff__content button {
    justify-self: end;
}