.filterItems__results {
    padding: 1.5em 1em 0 1em;
    --scrollbar-width: 8px;
    --mask-height: 1.5em;
    overflow-y: auto;
    max-height: 300px;
    padding-bottom: var(--mask-height);
    padding-right: 20px;
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 100% 0;
    mask-repeat: no-repeat, no-repeat;
    transition: .4s;
}

.filterItems__results{
    scrollbar-width: thin;
    scrollbar-color: currentColor transparent;
}

.filterItems__results::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.filterItems__results::-webkit-scrollbar-thumb {
    background-color: currentColor;
    border-radius: 9999px;
}

.filterItems__results::-webkit-scrollbar-track {
    background-color: transparent;
}

.filterItems__selected .card {
    padding: 1em;
    margin-bottom: .6em;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.filterItems__selected label {
    font-weight: 600;
}

.button {
    width: 100%;
}