.fileInput input[type="file"] {
    display: none;
}

.fileInput {
    display: flex;
    flex-wrap: wrap;
}

.fileInput > p {
    font-weight: 600;
    margin-bottom: 0.5em;
}

.fileInput__input {
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-basis: 100%;
}

.fileInput__input > label {
    color: black;
    background-color: white;
    border-radius: var(--primary-border-radius);
    border: 2px solid lightgray;
    font-weight: 600;
    text-align: center;
    padding: 1em;
    display: block;
}

.fileInput img {
    max-width: 100%;
    margin-bottom: 1em;
    border-radius: var(--primary-border-radius);
}

.input__image-wrapper {
    flex-basis: 100%;
    display: flex;
}