.animal-detail {
    display: grid;
    gap: 1em
}

.information {
    font-weight: 600;
}

.image {
    width: 100%;
}

.header {
    color: var(--primary-color);
}