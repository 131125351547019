import React from "react";
import AnimalTypeGroup from "../AnimalTypes/AnimalTypeGroup";
import CategoryGroup from "../Categories/CategoryGroup";
import CountryGroup from "../Countries/CountryGroup";
import * as classes from "./AdminPage.module.css";
import CreatePost from "../Posts/CreatePost";

const AdminPage = () => {
    return (
        <>
            <div className={classes["admin-page"]}>
                <CreatePost />
                <CountryGroup />
                <AnimalTypeGroup />
                <CategoryGroup />
            </div>
        </>
    );
};

export default AdminPage;
