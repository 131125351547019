import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as classes from "./AnimalSuggestions.module.css";
import { Link } from "react-router-dom";

const AnimalSuggestions = (props) => {
    const params = useParams();

    return (
        <>
            <div className={classes["list"]}>
                <h2>{props.header}</h2>
                {props.animals &&
                    props.animals.map((animal) => {
                        return (
                            <Link
                                to={`/djur/${params.animalTypePluralName}/${animal.id}`}
                                key={animal.id}
                            >
                                <div
                                    id={animal.id}
                                    className={classes["list-item"]}
                                >
                                    <img
                                        src={animal.imagePath}
                                        alt={`bild på ${animal.name}`}
                                        className={classes["image"]}
                                    />
                                    <h2>{animal.name}</h2>
                                </div>
                            </Link>
                        );
                    })}
            </div>
        </>
    );
};

export default AnimalSuggestions;
