import React, {useState} from "react";
import "./FormTabs.css";

const FormTab = (props) => {
    const classes = `formTab__label ${props.className}`;

    return (
        <>
            {props.activeTabId === "tab__" + props.id ? (
                <input
                    className="formTab__input"
                    type="radio"
                    id={"tab" + props.id}
                    name="tabs"
                    checked="checked"
                />
            ) : (
                <input
                    className="formTab__input"
                    type="radio"
                    id={"tab" + props.id}
                    name="tabs"
                />
            )}

            <label
                onClick={props.handleOnTabClick}
                id={"tab__" + props.id}
                className={classes}
                htmlFor={"tab" + props.id}
            >
                {props.form.props.name}
            </label>
            <div className={"formTabs__tab"}>{props.form}</div>
        </>
    );
};

export default FormTab;
