.navigation {
    display: flex;
    justify-content: center;
    overflow: visible;
}

.mobile-navigation,
.mobile-navigation__drop-down {
    display: none;
}

@media(max-width: 990px) {
    .navigation {
        display:none;
    }

    .mobile-navigation {
        display: flex;
        align-items: center;
        overflow: visible;
    }

    .mobile-navigation__hamburger {
        color: white;
        font-size: 2em;
        margin-right: 1em;
        cursor: pointer;
    }

    .mobile-navigation__drop-down {
        position: absolute;
        background-color: var(--primary-color);
        top:100%;
        width: 100%;
        min-height: 100vh;
        z-index: 5;
        display: flex;
        flex-direction: column;
    }
}