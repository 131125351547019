import * as classes from "./CategoryList.module.css";
import React from "react";
import Card from "../Ui/Card";

const CategoryList = (props) => {
    return (
        <>
            <ul className={classes['category-list']}>
                {props.categories.map((category) => (
                    <Card key={category.id} className={`${classes['category-list__card']}`}>
                        <li>{category.name}</li>
                    </Card>
                ))}
            </ul>
        </>
    );
};

export default CategoryList;
