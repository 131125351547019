.button {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.label {
    font-weight: 600;
    margin-bottom: .5em;
    display: flex;
}

.car {
    overflow: visible;
}