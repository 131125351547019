import React, { useState } from "react";
import Button from "../../Buttons/Button";
import Input from "../../Inputs/Input";
import TinyTextInput from "../../Inputs/TinyTextInput";
import FilterItems from "../../Filter/FilterItems";
import Card from "../../Ui/Card";
import Form from "../../Ui/Form";

const PuffForm = (props) => {
    const [enteredTitle, setTitle] = useState("");
    const [enteredText, setText] = useState("");
    const [enteredButtonText, setButtonText] = useState("Läs mer");
    const [selectedPost, setSelectedPost] = useState(null);

    const titleChangeHandler = (event) => {
        setTitle(event.target.value);
    };

    const textChangeHandler = (enteredText) => {
        setText(enteredText);
    };

    const buttonTextChangeHandler = (event) => {
        setButtonText(event.target.value);
    };

    const handleSelect = (post) => {
        if (selectedPost == null) {
            post.isPuff = true;
            setSelectedPost(post);
            setTitle(post.title);
        } else {
            post.isPuff = false;
            setSelectedPost(null);
            setTitle("");
        }
    };

    const handleClickClear = () => {
        setTitle("");
        setText("");
        setButtonText("Läs mer");
        if (selectedPost) selectedPost.isPuff = false;
        setSelectedPost(null);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        if (
            enteredTitle.trim().length === 0 ||
            enteredText.trim.length === 0 ||
            enteredButtonText.trim().length === 0
        ) {
            return;
        }

        const puffData = {
            title: enteredTitle,
            text: enteredText,
            buttonText: enteredButtonText,
        };

        props.onSaveTextPuff(puffData);
        setTitle("");
        setText("");
        setButtonText("Läs mer");
        setSelectedPost(null);
    };

    return (
        <>
            <Card>
                <Form className={props.className} onSubmit={submitHandler}>
                    <FilterItems
                        onSelect={handleSelect}
                        items={props.posts}
                        selectedItem={selectedPost}
                    />
                    <Input
                        label="Titel"
                        input={{
                            id: "textPuffTitle",
                            type: "text",
                            value: enteredTitle ,
                            onChange: { titleChangeHandler },
                        }}
                    />
                    <TinyTextInput textChangeHandler={textChangeHandler} />
                    <Input
                        label="Text på knappen"
                        input={{
                            id: "textPuffText",
                            type: "text",
                            onChange: { buttonTextChangeHandler },
                            value: enteredButtonText,
                        }}
                    />
                    <div className="puffForm__actions">
                        <Button type="submit" className={"button-submit"}>
                            Skapa Puff
                        </Button>
                        <Button
                            onClick={handleClickClear}
                            className={"button-clear"}
                        >
                            Rensa
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default PuffForm;
