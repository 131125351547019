import React, { useEffect } from "react";
import { useState } from "react";
import ImagePuffs from "../Puffs/ImagePuff/ImagePuffs";
import Puffs from "../Puffs/Puff/Puffs";
import AnimalPuffs from "../Puffs/AnimalPuff/AnimalPuffs";
import TextPuffs from "../Puffs/TextPuff/TextPuffs";
import FormTabs from "../Ui/FormTabs";
import NewAnimalPuff from "../Puffs/AnimalPuff/NewAnimalPuff";
import NewImagePuff from "../Puffs/ImagePuff/NewImagePuff";
import NewPuff from "../Puffs/Puff/NewPuff";
import NewTextPuff from "../Puffs/TextPuff/NewTextPuff";
import Axios from '../../assets/js/axios-instance';

const DUMMY_PUFFS = [
    {
        id: "1",
        title: "Välkommen till föreningen Hundar Längtar Hem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "2",
        title: "Stöd vårt arbete",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "3",
        title: "Vi söker jourhem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "14",
        title: "Bli medlem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "4444",
        title: "Bli medlem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "44",
        title: "Bli medlem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
    {
        id: "443",
        title: "Bli medlem",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
        link: "#",
    },
];

const DUMMY_IMAGE_PUFFS = [
    {
        id: "1",
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
    {
        id: "2",
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
    {
        id: "3",
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
];

const DUMMY_TEXT_PUFFS = [
    {
        id: "1",
        title: "Välkommen till förreningen",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        buttonText: "Läs mer",
    },
    {
        id: "2",
        title: "Här finns plats",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        buttonText: "Läs mer",
    },
    {
        id: "3",
        title: "Inget att göra åt",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        buttonText: "Läs mer",
    },
];

const DUMMY_ANIMAL_PUFFS = [
    {
        id: "1",
        name: "LAVINIA",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
    {
        id: "2",
        name: "BENGT",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
    {
        id: "3",
        name: "KARL",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veritatis incidunt tenetur eum sed quaerat quidem voluptates
                    reiciendis, odio sit aspernatur maxime doloremque nemo corrupti
                    porro quod cupiditate dolorem eos repellendus?`,
        image: "https://dummyimage.com/1920x1080/aaa/fff",
        buttonText: "Läs mer",
    },
];

const DUMMY_POSTS = [
    {
        id: "1",
        title: "Scabby",
        htmlContent: "<h1>detta fungerar 😀</h1>",
        carouselImages: `
            https://dummyimage.com/1920x1080/aaa/fff, 
            https://dummyimage.com/1920x1080/aaa/fff,
            https://dummyimage.com/1920x1080/aaa/fff`,
        category: {
            id: "1",
            name: "Jobba på",
        },
        created: "20220601",
        isPuff: false,
    },
    {
        id: "2",
        title: "Något annat 🤣",
        htmlContent: "<h1>detta fungerar igen 😀</h1>",
        carouselImages: `
            https://dummyimage.com/1920x1080/aaa/fff, 
            https://dummyimage.com/1920x1080/aaa/fff,
            https://dummyimage.com/1920x1080/aaa/fff`,
        category: {
            id: "2",
            name: "Jobba på men prata med",
        },
        created: "20220603",
        isPuff: false,
    },
    {
        id: "3",
        title: "Nytt",
        htmlContent: "<h1>detta fungerar och igen 😀</h1>",
        carouselImages: `
            https://dummyimage.com/1920x1080/aaa/fff, 
            https://dummyimage.com/1920x1080/aaa/fff,
            https://dummyimage.com/1920x1080/aaa/fff`,
        category: {
            id: "3",
            name: "Jobba inte på",
        },
        created: "20220603",
        isPuff: false,
    },
    {
        id: "4",
        title: "borde komma sist",
        htmlContent: "<h1>detta fungerar och igen 😀</h1>",
        carouselImages: `
            https://dummyimage.com/1920x1080/aaa/fff, 
            https://dummyimage.com/1920x1080/aaa/fff,
            https://dummyimage.com/1920x1080/aaa/fff`,
        category: {
            id: "3",
            name: "Jobba inte på",
        },
        created: "20010603",
        isPuff: false,
    },
    {
        id: "5",
        title: "Borde komma först",
        htmlContent: "<h1>detta fungerar och igen 😀</h1>",
        carouselImages: `
            https://dummyimage.com/1920x1080/aaa/fff, 
            https://dummyimage.com/1920x1080/aaa/fff,
            https://dummyimage.com/1920x1080/aaa/fff`,
        category: {
            id: "3",
            name: "Jobba inte på",
        },
        created: "20240603",
        isPuff: false,
    },
];

const PuffPage = () => {
    const [puffs, setPuffs] = useState(DUMMY_PUFFS);
    const [imagePuffs, setImagePuffs] = useState(DUMMY_IMAGE_PUFFS);
    const [textPuffs, setTextPuffs] = useState(DUMMY_TEXT_PUFFS);
    const [animalPuffs, setAnimalPuffs] = useState(DUMMY_ANIMAL_PUFFS);
    
    const [animals, setAnimals] = useState([]);

    const getAnimals = async () => {
        await Axios.get("/animal/all")
            .then((response) => {
                const data = response.data;
                setAnimals(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getAnimals();
    }, []);

    const addPuffHandler = (puff) => {
        setPuffs((prevPuffs) => {
            return [puff, ...prevPuffs];
        });
    };
    const addImagePuffHandler = (puff) => {
        setImagePuffs((prevPuffs) => {
            return [puff, ...prevPuffs];
        });
    };
    const addTextPuffHandler = (puff) => {
        setTextPuffs((prevPuffs) => {
            return [puff, ...prevPuffs];
        });
    };
    const addAnimalPuffHandler = (puff) => {
        setAnimalPuffs((prevPuffs) => {
            return [puff, ...prevPuffs];
        });
    };

    return (
        <>
            <FormTabs
                forms={[
                    <NewTextPuff
                        onAddTextPuff={addTextPuffHandler}
                        posts={DUMMY_POSTS}
                        id="form_1"
                        name="Textpuff"
                        color="living-coral"
                        className="living-coral"
                    />,
                    <NewImagePuff
                        onAddImagePuff={addImagePuffHandler}
                        posts={DUMMY_POSTS}
                        id="form_2"
                        className="moss-green"
                        name="Bildpuff"
                    />,
                    <NewPuff
                        onAddPuff={addPuffHandler}
                        posts={DUMMY_POSTS}
                        id="form_4"
                        className="light-blue"
                        name="Puff"
                    />,
                ]}
            />

            <ImagePuffs imagePuffs={imagePuffs} />
            <TextPuffs textPuffs={textPuffs} />
            <Puffs puffs={puffs} />
            <AnimalPuffs animalPuffs={animalPuffs} />
        </>
    );
};

// ,
//                     <NewAnimalPuff
//                         onAddAnimalPuff={addAnimalPuffHandler}
//                         animals={animals && animals.filter((animal) => animal.animalType.name.toLowerCase() === 'hund')}
//                         id="form_3"
//                         className="aspen-gold"
//                         name="Hundpuff"
//                     />

export default PuffPage;
