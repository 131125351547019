import React from "react";
import Button from "../../Buttons/Button";
import Card from "../../Ui/Card";
import * as
    classes from "./TextPuff.module.css";

const TextPuff = (props) => {
    return (
        <>
            <Card className={classes["textPuff"]}>
                <h1>{props.textPuff.title}</h1>
                <p>{props.textPuff.text}</p>
                <Button className="border">{props.textPuff.buttonText}</Button>
            </Card>
        </>
    );
};

export default TextPuff;
