import React, { useState } from "react";
import Input from "../Inputs/Input";
import Card from "../Ui/Card";
import Button from "../Buttons/Button";
import * as classes from "./ItemLinking.module.css";
import { RiCloseLine } from "react-icons/ri";

const ItemLinking = (props) => {
    if (props.selectedItem) {
        return (
            <>
                <div className={classes.car}>
                    <label className={classes.label}>Länka</label>
                    <Card>
                        <Button
                            className={classes.button}
                            onClick={props.handleSelectedClick}
                        >
                            {props.selectedItem.title}
                            <RiCloseLine />
                        </Button>
                    </Card>
                </div>
            </>
        );
    }
    return (
        <Input
            label="Länka"
            input={{
                onChange: props.searchChangeHandler,
            }}
        />
    );
};

export default ItemLinking;
