import React from "react";
import * as
    classes from "./AnimalPuff.module.css";
import Card from "../../Ui/Card";
import Button from "../../Buttons/Button";

const AnimalPuff = (props) => {
    return (
        <>
            <Card className={classes["animalPuff"]}>
                <img src={props.animalPuff.image} alt="" />
                    <div className={classes["animalPuff__content"]}>
                        <h1>
                            Veckans Hund: <br />
                            {props.animalPuff.name}
                        </h1>
                        <p>{props.animalPuff.text}</p>
                        <Button className="border">
                            {props.animalPuff.buttonText}
                        </Button>
                    </div>
            </Card>
        </>
    );
};

export default AnimalPuff;
