import React from 'react'
import TextPuff from './TextPuff';

const TextPuffs = (props) => {
    return (
        <>
            {props.textPuffs.map((textPuff) => (
                <TextPuff key={textPuff.id} textPuff={textPuff} />
            ))}
        </>
    );
}

export default TextPuffs