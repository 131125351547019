.select-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.select-wrapper label {
    margin-bottom: .5em;
    font-weight: 600;
}

.select {
    font-size: 1.5em;
    padding: .5em 1.5em;
    border-radius: var(--primary-border-radius);
    border: 2px solid lightgrey;
}