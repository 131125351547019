import React, {useState} from "react";
import AnimalPuff from "./AnimalPuff";

const AnimalPuffs = (props) => {
    return (
        <>
            {props.animalPuffs.map((animalPuff) => (
                <AnimalPuff key={animalPuff.id} animalPuff={animalPuff} />
            ))}
        </>
    );
};

export default AnimalPuffs;
