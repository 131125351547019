.animalPuff img {
    width: 100%;
    border-radius: var(--primary-border-radius) var(--primary-border-radius) 0 0;
    display: flex;
}

.animalPuff__content {
    display: grid;
    gap: 1em;
    padding: 1em;
}

.animalPuff__content button {
    justify-self: end;
}


@media screen and (min-width: 1200px) {
    .animalPuff__wrapper {
        display: flex;
    }

    .animalPuff__wrapper > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .animalPuff__wrapper img {
        border-radius: var(--primary-border-radius) 0 0
            var(--primary-border-radius);
    }
}
