import React, { useEffect, useState } from "react";
import htmlParser from "html-react-parser";
import Carousel from "../Ui/Carousel";
import * as classes from "./AnimalDetail.module.css";

const AnimalDetail = (props) => {
    return (
        <>
            {props.animal && (
                <div className={classes["animal-detail"]}>
                    <h1 className={classes["header"]}>
                        {props.animal.name.toUpperCase()}
                    </h1>
                    <div>
                        <div>
                            <img
                                className={classes["image"]}
                                src={props.animal.imagePath}
                                alt={`Bild på ${props.animal.name}`}
                            />
                            <div className={classes["information"]}>
                                <p>{`${props.animal.sex}, född ${props.animal.born}`}</p>
                                <p>{`Storlek: ${props.animal.size}`}</p>
                                <p>{`Adoptionskostnad: ${props.animal.price}`}</p>
                            </div>
                            {htmlParser(props.animal.article.htmlContent)}
                            <Carousel images={props.animal.article.carouselImages} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AnimalDetail;
