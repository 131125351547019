import * as classes from "./AnimalTypeGroup.module.css";
import React, { useEffect, useState } from "react";
import Card from "../Ui/Card";
import AnimalTypeList from "./AnimalTypeList";
import AnimalTypeForm from './AnimalTypeForm';
import Axios from "../../assets/js/axios-instance";

const AnimalTypeGroup = () => {
    const [animalTypes, setAnimalTypes] = useState([]);

    useEffect(() => {
        getAnimalTypes();
    }, []);

    const postAnimalTypeHandler = async (newAnimalType) => {
        await Axios.post('/animaltype/create', newAnimalType)
            .then((response) => {
                newAnimalTypeHandler(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const newAnimalTypeHandler = (newAnimalType) => {
        setAnimalTypes((prevAnimalTypes) => {
            return [...prevAnimalTypes, newAnimalType];
        });
    };

    const getAnimalTypes = async () => {
        await Axios.get('/animaltype/all')
            .then((response) => {
                setAnimalTypes(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Card className={classes["animal-type-group"]}>
                <h2 className={classes["header"]}>DJURTYP</h2>
                <AnimalTypeForm onSubmit={postAnimalTypeHandler} />
                <AnimalTypeList animalTypes={animalTypes} />
            </Card>
        </>
    );
};

export default AnimalTypeGroup;
