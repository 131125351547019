import React, { useState, useEffect } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import * as classes from "./Carousel.module.css";

const Carousel = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [arrayLength, setArrayLength] = useState(props.images.length);

    useEffect(() => {
        setArrayLength(props.images.length);
    }, [props.images.length]);

    const onClickNextImage = () => {
        console.log(arrayLength);
        setCurrentIndex(
            currentIndex === arrayLength - 1 ? 0 : currentIndex + 1
        );
    };

    const onClickPrevImage = () => {
        console.log(arrayLength);
        setCurrentIndex(
            currentIndex === 0 ? arrayLength - 1 : currentIndex - 1
        );
    };

    if (!Array.isArray(props.images) && slides.lenght < 1) {
        return null;
    }

    return (
        <>
            <div>
                {props.label && (
                    <label className={classes["carousel__label"]}>
                        {props.label}
                    </label>
                )}
                <div className={classes["carousel"]}>
                    <FaArrowAltCircleLeft
                        className={`${classes["carousel__arrow"]} ${classes["carousel__arrow--left"]}`}
                        onClick={onClickPrevImage}
                    />
                    <FaArrowAltCircleRight
                        className={`${classes["carousel__arrow"]} ${classes["carousel__arrow--right"]}`}
                        onClick={onClickNextImage}
                    />
                    {props.images.map((image, index) => {
                        return (
                            <img
                                className={
                                    index === currentIndex
                                        ? `${classes["carousel__image"]} ${classes["carousel__image--active"]}`
                                        : `${classes["carousel__image"]}`
                                }
                                key={index}
                                src={image}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Carousel;
