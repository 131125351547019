import React from "react";
import TinyMCE from "../TinyMCE/TinyMCE";

const TinyTextInput = ({ textChangeHandler }) => {
    return (
        <>
            <div className="input">
                <label htmlFor="">Text</label>
                <TinyMCE textChangeHandler={textChangeHandler} />
            </div>
        </>
    );
};

export default TinyTextInput;
