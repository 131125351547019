import React, { useState, useMemo, useEffect } from "react";
import * as classes from "./FilterItems.module.css";
import ItemLinking from "./ItemLinking";
import Card from "../Ui/Card";
import Button from "../Buttons/Button";

const FilterItems = ({ items, onSelect, selectedItem }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [resultsHeight, setResultsHeight] = useState({
        maxHeight: "0px",
        padding: "0px",
    });
    const [sortedList, setSortedList] = useState([]);

    const handleClickSelect = (event) => {
        const item = items.find((i) => i.id == event.target.id);
        item.isPuff = true;
        onSelect(item);
        setResultsHeight({ maxHeight: "0px", padding: "0px" });
    };

    const handleSelectedClick = () => {
        setSearchTerm("");
        onSelect(selectedItem);
    };

    useEffect(() => {
        if (items.length > 0) {
            setSortedList(items.sort((a, b) => a.title.localeCompare(b.title)));
        }
    }, [items]);

    let handleFocus = () => {
        setResultsHeight({ maxHeight: "300px" });
    };

    let handleBlur = () => {
        setResultsHeight({ maxHeight: "0px", padding: "0px" });
    };

    const searchChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <>
            <div
                onFocus={handleFocus}
                onBlur={handleBlur}
            >
                <ItemLinking
                    handleSelectedClick={handleSelectedClick}
                    selectedItem={selectedItem}
                    searchChangeHandler={searchChangeHandler}
                />

                <div
                    style={resultsHeight}
                    className={classes["filterItems__results"]}
                >
                    {sortedList
                        .filter((item) => {
                            if (
                                !item.isPuff &&
                                item.title
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                            ) {
                                return item;
                            }
                        })
                        .map((item, key) => {
                            return (
                                <Card key={item.id}>
                                    <Button
                                        id={item.id}
                                        onClick={handleClickSelect}
                                    >
                                        {item.title}
                                    </Button>
                                </Card>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default FilterItems;
