import React, { useEffect, useState } from "react";
import * as classes from "./FileInput.module.css";
import { RiImage2Line } from "react-icons/ri";

const InputFile = React.forwardRef((props, ref) => {
    const [preview, setPreview] = useState();
    const [buttonText, setButtonText] = useState();

    useEffect(() => {
        setPreview(props.preview ? props.preview : null);
        setButtonText(props.preview ? "Ändra Bild" : "Lägg Till Bild");
    }, []);

    const onFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const src = URL.createObjectURL(file);
            setPreview(src);
            setButtonText("Ändra Bild");
            props.onChange(event);
        }
    };

    return (
        <>
            <div className={`${classes["fileInput"]}`}>
                <p>{props.label}</p>
                <div className={classes["input__image-wrapper"]}>
                    {preview && <img src={preview} alt="" />}
                </div>
                <div className={classes["fileInput__input"]}>
                    <label htmlFor={props.id}>
                        <RiImage2Line />
                        &nbsp; {buttonText}
                    </label>
                    <input
                        ref={ref}
                        type="file"
                        id={props.id}
                        accept="image/*"
                        onChange={onFileChange}
                    />
                </div>
            </div>
        </>
    );
});

export default InputFile;
