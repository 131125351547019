import React, { useState, useEffect } from "react";
import AnimalForm from "./AnimalForm";
import Axios from "../../assets/js/axios-instance";

const NewAnimal = (props) => {
    const [animalTypes, setAnimalTypes] = useState([]);
    const [countries, setCountries] = useState([]);

    const getAnimalTypes = async () => {
        await Axios.get("/animalType/all")
            .then((response) => {
                setAnimalTypes(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getCountries = async () => {
        await Axios.get("/country/all")
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getAnimalTypes();
        getCountries();
    }, []);

    const onPostAnimalForm = async (animalFormData) => {
        await Axios.post("/animal/create", animalFormData)
            .then((response) => {
                props.onNewAnimal(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            {animalTypes.length > 0 && countries.length > 0 && (
                <AnimalForm
                    onPost={onPostAnimalForm}
                    animalTypes={animalTypes}
                    countries={countries}
                />
            )}
        </>
    );
};

export default NewAnimal;
