.main-header {
    display:flex;
    height: 80px;
    background-color: var(--primary-color);
    justify-content: space-between;
    position: relative;
    overflow: visible;
}

.main-header__logo {
    display: flex;
    align-items: center;
    color: white;
    padding: .5em;
    max-height: 100%;
    overflow: hidden;
}

.link {
    height: 100%;
}

.main-header__logo h1 {
    padding: 1rem 0;
}

.image {
    height: 80px;
}