import React, { useEffect, useState } from "react";
import * as classes from "./InputFileCarousel.module.css";
import { TiDeleteOutline } from "react-icons/ti";
import { RiImageEditFill } from "react-icons/ri";
import Button from "../Buttons/Button";

const InputFileCarousel = (props) => {
    const [preview, setPreview] = useState();

    useEffect(() => {
        setPreview(props.preview);
    }, [])
    
    const onFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const src = URL.createObjectURL(file);
            setPreview(src);
            props.onChange(event);
        }
    };

    return (
        <>
            <div className={`${classes["input-container"]}`}>
                <div className={classes["options"]}>
                    <div className={classes["input-wrapper"]}>
                        <label htmlFor={props.id}>
                            {preview && <img src={preview} alt="" />}
                            <RiImageEditFill
                                className={classes["input-icon"]}
                            />
                        </label>
                        <input
                            type="file"
                            id={props.id}
                            accept="image/*"
                            onChange={onFileChange}
                        />
                    </div>
                    <div>
                        <Button
                            id={props.id}
                            onClick={props.onClick}
                            className={classes["button"]}
                        >
                            <TiDeleteOutline className={classes['button-icon']} />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InputFileCarousel;
