import React from "react";
import './NewPuff.css'
import PuffForm from "./PuffForm";

const NewPuff = ({ onAddPuff, posts, id, className }) => {
    const savePuffDataHandler = (puffData) => {
        puffData = {
            ...puffData,
            id: Math.random().toString(),
        };
        onAddPuff(puffData);
    };
    
    return (
        <>
            <PuffForm
                onSavePuffData={savePuffDataHandler}
                posts={posts}
                id={id}
                className={className}
            />
        </>
    );
};

export default NewPuff;
