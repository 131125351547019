import * as classes from "./Puff.module.css";
import Card from "../../Ui/Card";
import Button from "../../Buttons/Button";
import React, { useState, useEffect } from "react";

export default function Puff(props) {
    return (
        <>
            <Card className={classes["puff"]}>
                <img src={props.puff.image} alt="" />
                <div className={classes["puff__content"]}>
                    <h1>{props.puff.title}</h1>
                    <p>{props.puff.text}</p>
                    <Button className="border">{props.puff.buttonText}</Button>
                </div>
            </Card>
        </>
    );
}
