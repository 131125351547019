import React, { useState, useEffect } from "react";
import * as classes from "./AnimalList.module.css";
import Card from "../Ui/Card";
import { Link } from "react-router-dom";

const AnimalList = (props) => {
    const [animals, setAnimals] = useState(props.animals);

    useEffect(() => {
        setAnimals(props.animals);
    }, [props.animals])

    return (
        <>
            <div className={classes["animal-list"]}>
                {animals &&
                    animals.map((animal) => {
                        return (
                            <Link to={`${animal.id}`} key={animal.id}>
                                <Card className={classes["card"]}>
                                    <img
                                        className={classes["image"]}
                                        src={animal.imagePath}
                                        alt={`Bild på ${animal.name}`}
                                    />
                                    <div className={classes["info-wrapper"]}>
                                        <h3>{animal.name.toUpperCase()}</h3>
                                        <p>{`${animal.sex}, ${animal.born}`}</p>
                                        <p>{`Storlek: ${animal.size}`}</p>
                                        <p>{`Adoptionskostnad: ${animal.price}`}</p>
                                    </div>
                                </Card>
                            </Link>
                        );
                    })}
            </div>
        </>
    );
};

export default AnimalList;
