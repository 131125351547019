.inputs {
    background-color: var(--primary-background-color);
}

.input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.input label {
    margin-bottom: 0.5em;
    font-weight: 600;
}

.input input {
    padding: 1em;
    border: 2px solid lightgrey;
    border-radius: var(--primary-border-radius);
    font-size: 16px;
}

.input textarea {
    padding: 1em;
    border: 2px solid lightgrey;
    border-radius: var(--primary-border-radius) var(--primary-border-radius) 0
        var(--primary-border-radius);
    font-size: 16px;
    resize: vertical;
    height: 7em;
}

.input input:focus,
.input textarea:focus {
    border: 2px solid var(--primary-color);
    background-color: var(--primary-background-color);
}

.input input:focus,
.input textare:focus {
    outline: none;
}

.invalid textarea,
.invalid input {
    border: 2px solid #b40e0e;
    background-color: #fddddd;
}

.invalid textarea:focus,
.invalid input:focus {
    border: 2px solid #ff8800;
    background-color: #fbe8d2;
}

.invalid-message {
    color: #b40e0e;
}
