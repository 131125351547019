import React, { useEffect, useState, useRef } from "react";
import Form from "../Ui/Form";
import Card from "../Ui/Card";
import Input from "../Inputs/Input";
import InputCarousel from "../Inputs/InputCarousel";
import Button from "../Buttons/Button";
import useInput from "../Hooks/useInput";
import Select from "../Inputs/Select";
import LargeMCEInput from "../Inputs/LargeMCEInput";

const AnimalForm = (props) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [carouselImages, setCarouselImages] = useState([]);
    const [tinyText, setTinyText] = useState("");

    useEffect(() => {
        if (props.categories.length > 0) {
            setSelectedCategoryId(props.categories[0].id);
        }
    }, [props.categories]);

    const onChangeCarousel = (files) => {
        setCarouselImages(files);
    };

    const categoryChangeHandler = (event) => {
        console.log(selectedCategoryId);
        setSelectedCategoryId(event.target.value);
    };

    const isAboveTwoCharacters = (value) => value.trim().length >= 2;

    const {
        value: enteredTitle,
        isValid: enteredTitleIsValid,
        hasError: titleInputHasError,
        valueChangeHandler: titleChangedHandler,
        inputBlurHandler: titleBlurHandler,
        reset: resetTitleInput,
    } = useInput(isAboveTwoCharacters);

    let formIsValid = false;

    if (enteredTitleIsValid) {
        formIsValid = true;
    }

    const onMceChange = (text) => {
        setTinyText(text);
    };

    const formSubmissionHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        console.log(`${enteredTitle} ${selectedCategoryId} ${tinyText} ${carouselImages}`)

        const fd = new FormData();
        fd.append("title", enteredTitle);
        fd.append("categoryId", selectedCategoryId);
        fd.append("htmlContent", tinyText);
        carouselImages.map((image) => {
            fd.append("carouselImages", image);
        });

        props.onPost(fd);

        resetTitleInput();
    };

    return (
        <>
            <Card>
                <h2>{`SKAPA INLÄGG`}</h2>
                <Form
                    enctype="multipart/form-data"
                    onSubmit={formSubmissionHandler}
                >
                    <Input
                        label="Titel"
                        input={{
                            id: "title",
                            type: "text",
                            onChange: titleChangedHandler,
                            value: enteredTitle,
                            onBlur: titleBlurHandler,
                        }}
                        isValid={!titleInputHasError}
                        errorMessage="Titeln behöver innehålla minst 2 tecken"
                    />

                    <Select
                        onChange={categoryChangeHandler}
                        id={"selectCategory"}
                        label="kategori"
                        value={selectedCategoryId}
                    >
                        {props.categories.map((category) => {
                            return (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            );
                        })}
                    </Select>

                    <LargeMCEInput onChange={onMceChange} />

                    <InputCarousel
                        images={carouselImages}
                        onChange={onChangeCarousel}
                    />

                    <div>
                        <Button
                            disabled={!formIsValid}
                            type="submit"
                            className={"submit"}
                        >
                            Skapa Inlägg
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default AnimalForm;
