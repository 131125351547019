import Input from "../Inputs/Input";
import Form from "../Ui/Form";
import Button from "../Buttons/Button";
import useInput from "../Hooks/useInput";

const AnimalTypeForm = (props) => {
    const isAboveTwoCharacters = (value) => value.trim().length >= 2;

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredPluralName,
        isValid: enteredPluralNameIsValid,
        hasError: pluralNameInputHasError,
        valueChangeHandler: pluralNameChangedHandler,
        inputBlurHandler: pluralNameBlurHandler,
        reset: resetPluralNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredFemaleName,
        isValid: enteredFemaleNameIsValid,
        hasError: femaleNameInputHasError,
        valueChangeHandler: femaleNameChangedHandler,
        inputBlurHandler: femaleNameBlurHandler,
        reset: resetFemaleNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredMaleName,
        isValid: enteredMaleNameIsValid,
        hasError: maleNameInputHasError,
        valueChangeHandler: maleNameChangedHandler,
        inputBlurHandler: maleNameBlurHandler,
        reset: resetMaleNameInput,
    } = useInput(isAboveTwoCharacters);

    let formIsValid = false;

    if (
        enteredNameIsValid &&
        enteredPluralNameIsValid &&
        enteredFemaleNameIsValid &&
        enteredMaleNameIsValid
    ) {
        formIsValid = true;
    }

    const onSubmissionHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        const animalTypeObj = {
            name: enteredName,
            pluralName: enteredPluralName,
            femaleName: enteredFemaleName,
            maleName: enteredMaleName
        }

        props.onSubmit(animalTypeObj);

        resetNameInput();
        resetPluralNameInput();
        resetFemaleNameInput();
        resetMaleNameInput();
    };

    return (
        <>
            <Form onSubmit={onSubmissionHandler}>
                <Input
                    label="Namn"
                    input={{
                        id: "animalTypeName",
                        type: "text",
                        onChange: nameChangedHandler,
                        value: enteredName,
                        onBlur: nameBlurHandler,
                    }}
                    isValid={!nameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />
                <Input
                    label="Pluralnamn"
                    input={{
                        id: "animalTypePluarlName",
                        type: "text",
                        onChange: pluralNameChangedHandler,
                        value: enteredPluralName,
                        onBlur: pluralNameBlurHandler,
                    }}
                    isValid={!pluralNameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />
                <Input
                    label="Namn på honan"
                    input={{
                        id: "animalFemaleName",
                        type: "text",
                        onChange: femaleNameChangedHandler,
                        value: enteredFemaleName,
                        onBlur: femaleNameBlurHandler,
                    }}
                    isValid={!femaleNameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />
                <Input
                    label="Namn på hanen"
                    input={{
                        id: "animalMaleName",
                        type: "text",
                        onChange: maleNameChangedHandler,
                        value: enteredMaleName,
                        onBlur: maleNameBlurHandler,
                    }}
                    isValid={!maleNameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />
                <Button
                    disabled={!formIsValid}
                    type="submit"
                    className={"green"}
                >
                    Lägg Till Djur
                </Button>
            </Form>
        </>
    );
};

export default AnimalTypeForm;
