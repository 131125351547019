.animal-list {
    display: grid;
    gap: 1em;
}

.image {
    width: 100%;
}

.info-wrapper {
    padding: 1em;
    color: var(--primary-color);
}

.info-wrapper > h3 {
    margin-bottom: 0.5em;
    font-size: 1.5rem;
}

.card {
    background-color: var(--primary-background-color);
}
