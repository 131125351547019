.auth-group {
    background-color: var(--primary-color);
}

.auth-group label {
    color: white;
}

.header {
    text-align: center;
    margin-top: 1em;
    color: white;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
} 