import React, { useState } from "react";
import Button from "../../Buttons/Button";
import FilterItems from "../../Filter/FilterItems";
import InputFile from "../../Inputs/FileInput";
import Input from "../../Inputs/Input";
import Card from "../../Ui/Card";
import Form from "../../Ui/Form";
import "../Puff/PuffForm.css";

const ImagePuffForm = (props) => {
    const [file, setFile] = useState("");
    const [fileButtonText, setFileButtonText] = useState("Lägg till bild");
    const [buttonText, setButtonText] = useState("Läs mer");
    const [selectedPost, setSelectedPost] = useState(null);

    const fileChangeHandler = (event) => {
        let [files] = event.target.files;
        if (files) {
            let src = URL.createObjectURL(files);
            setFileButtonText("Ändra bild");
            setFile(src);
        }
    };

    const buttonTextChangeHandler = (event) => {
        setButtonText(event.target.value);
    };

    const handleSelect = (post) => {
        if (selectedPost == null) {
            post.isPuff = true;
            setSelectedPost(post);
        } else {
            post.isPuff = false;
            setSelectedPost(null);
        }
    };

    const handleClickClear = () => {
        setFile("");
        setFileButtonText("Lägg till bild");
        setButtonText("Läs mer");
        if (selectedPost) selectedPost.isPuff = false;
        setSelectedPost(null);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        const puffData = {
            image: file,
            buttonText: buttonText,
        };

        props.onSaveImagePuff(puffData);
        setFile("");
        setFileButtonText("Lägg till bild");
        setButtonText("Läs mer");
        setSelectedPost(null);
    };

    return (
        <Card>
            <Form className={props.className} onSubmit={submitHandler}>
                <FilterItems
                    onSelect={handleSelect}
                    items={props.posts}
                    selectedItem={selectedPost}
                />
                <InputFile
                    label="Bild"
                    htmlFor=""
                    imgSrc={file}
                    buttonText={fileButtonText}
                    onChange={fileChangeHandler}
                    id={props.id}
                />
                <Input
                    label="Text på knappen"
                    input={{
                        id: "imageText",
                        type: "text",
                        onChange: {buttonTextChangeHandler},
                        value: buttonText
                    }}
                />
                <div className="puffForm__actions">
                    <Button type="submit" className={"button-submit"}>
                        Skapa Puff
                    </Button>
                    <Button
                        onClick={handleClickClear}
                        className={"button-clear"}
                    >
                        Rensa
                    </Button>
                </div>
            </Form>
        </Card>
    );
};

export default ImagePuffForm;
