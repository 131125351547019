import { createRoot } from "react-dom/cjs/react-dom.production.min";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { React } from "react";
import { AuthContextProvider } from "./components/Context/AuthContext";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <AuthContextProvider>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </AuthContextProvider>
);