import axios from "axios";

export const debuggerInstance = axios.create({
    baseURL: 'https://localhost:44398/api'
})

const instance = axios.create({
    baseURL: 'https://api.kimkristiansson.se/api'
});

export default instance;