import React, { useEffect, useState, useRef } from "react";
import * as classes from "./AnimalForm.module.css";
import Form from "../Ui/Form";
import Card from "../Ui/Card";
import Input from "../Inputs/Input";
import InputCarousel from "../Inputs/InputCarousel";
import Button from "../Buttons/Button";
import useInput from "../Hooks/useInput";
import InputFile from "../Inputs/FileInput";
import Select from "../Inputs/Select";
import LargeMCEInput from "../Inputs/LargeMCEInput";

const AnimalForm = (props) => {
    const [file, setFile] = useState({});
    const [imgSrc, setImgSrc] = useState();
    const [selectedSex, setSelectedSex] = useState();
    const [selectedAnimalType, setSelectedAnimalType] = useState(
        props.animalTypes[0]
    );
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [carouselImages, setCarouselImages] = useState([]);
    const [tinyText, setTinyText] = useState("");
    const fileInputRef = useRef();

    useEffect(() => {
        if (props.countries.length > 0) {
            setSelectedCountryId(props.countries[0].id);
        }

        setSelectedSex(props.animalTypes[0].femaleName);
    }, []);

    useEffect(() => {
        setSelectedSex(selectedAnimalType.femaleName);
    }, [selectedAnimalType]);

    const fileChangeHandler = (event) => {
        const targetFile = event.target.files[0];
        setFile(event.target.files[0]);
        if (targetFile) {
            const reader = new FileReader();

            reader.onload = (x) => {
                setImgSrc(x.target.result);
            };

            reader.readAsDataURL(targetFile);
        }
    };

    const animalTypeChangeHandler = (event) => {
        setSelectedAnimalType(
            props.animalTypes.find(
                (animalType) => animalType.id == event.target.value
            )
        );
    };

    const sexSelectChangeHandler = (event) => {
        setSelectedSex(event.target.value);
    };

    const countryChangeHandler = (event) => {
        setSelectedCountryId(event.target.value);
    };

    const onChangeCarousel = (files) => {
        setCarouselImages(files);
    };

    const isAboveTwoCharacters = (value) => value.trim().length >= 2;
    const isHigherThanZero = (value) => value > 0;

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredBirth,
        isValid: enteredBirthIsValid,
        hasError: birthInputHasError,
        valueChangeHandler: birthChangedHandler,
        inputBlurHandler: birthBlurHandler,
        reset: resetBirthInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredSize,
        isValid: enteredSizeIsValid,
        hasError: sizeInputHasError,
        valueChangeHandler: sizeChangedHandler,
        inputBlurHandler: sizeBlurHandler,
        reset: resetSizeInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredPrice,
        isValid: enteredPriceIsValid,
        hasError: priceInputHasError,
        valueChangeHandler: priceChangedHandler,
        inputBlurHandler: priceBlurHandler,
        reset: resetPriceInput,
    } = useInput(isHigherThanZero);

    let formIsValid = false;

    if (
        enteredNameIsValid &&
        enteredBirthIsValid &&
        enteredSizeIsValid &&
        enteredPriceIsValid
    ) {
        formIsValid = true;
    }

    const onMceChange = (text) => {
        setTinyText(text);
    };

    const formSubmissionHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        const fd = new FormData();
        fd.append("name", enteredName);
        fd.append("born", enteredBirth);
        fd.append("imageFile", file);
        fd.append("sex", selectedSex);
        fd.append("isCastrated", true);
        fd.append("animalTypeId", selectedAnimalType.id);
        fd.append("countryId", selectedCountryId);
        fd.append("size", enteredSize);
        fd.append("price", enteredPrice);
        fd.append("isPuff", false);
        fd.append("htmlContent", tinyText);
        carouselImages.map((image) => {
            fd.append("carouselImages", image);
        });

        console.log(fd);

        props.onPost(fd);

        console.log(fileInputRef.current.value);
        fileInputRef.current.value = "";
        resetNameInput();
        resetBirthInput();
        resetSizeInput();
        resetPriceInput();
        setCarouselImages([]);
    };

    return (
        <>
            <Card className={classes["card"]}>
                <h2>{ `SKAPA ${selectedAnimalType.name.toUpperCase()}`}</h2>
                <Form
                    enctype="multipart/form-data"
                    onSubmit={formSubmissionHandler}
                >
                    <InputFile
                        label="Bild"
                        htmlFor="animalImg"
                        imgSrc={imgSrc}
                        onChange={fileChangeHandler}
                        id={"animalFile"}
                        ref={fileInputRef}
                    />

                    <Select
                        onChange={animalTypeChangeHandler}
                        id={"selectAnimalType"}
                        label="Djur"
                        value={selectedAnimalType.id}
                    >
                        {props.animalTypes.map((animalType) => {
                            return (
                                <option
                                    key={animalType.id}
                                    value={animalType.id}
                                >
                                    {animalType.name}
                                </option>
                            );
                        })}
                    </Select>

                    <Select
                        onChange={sexSelectChangeHandler}
                        id={"sexSelectAnimalType"}
                        label="Kön"
                        value={selectedSex}
                    >
                        <option value={selectedAnimalType.femaleName}>
                            {selectedAnimalType.femaleName}
                        </option>
                        <option value={selectedAnimalType.maleName}>
                            {selectedAnimalType.maleName}
                        </option>
                    </Select>

                    <Select
                        onChange={countryChangeHandler}
                        id={"countrySelectAnimalType"}
                        label="Land"
                        value={selectedCountryId}
                    >
                        {props.countries.map((country, key) => {
                            return (
                                <option
                                    key={country.name + key}
                                    value={country.id}
                                >
                                    {country.name}
                                </option>
                            );
                        })}
                    </Select>

                    <Input
                        label="Namn"
                        input={{
                            id: "animalName",
                            type: "text",
                            onChange: nameChangedHandler,
                            value: enteredName,
                            onBlur: nameBlurHandler,
                        }}
                        isValid={!nameInputHasError}
                        errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                    />

                    <Input
                        label="Född"
                        input={{
                            id: "birthName",
                            type: "text",
                            onChange: birthChangedHandler,
                            value: enteredBirth,
                            onBlur: birthBlurHandler,
                        }}
                        isValid={!birthInputHasError}
                        errorMessage="Födelsedatumet behöver innehålla minst 2 bokstäver"
                    />

                    <Input
                        label="Storlek"
                        input={{
                            id: "size",
                            type: "text",
                            onChange: sizeChangedHandler,
                            value: enteredSize,
                            onBlur: sizeBlurHandler,
                        }}
                        isValid={!sizeInputHasError}
                        errorMessage="Storlek behöver innehålla minst 2 bokstäver"
                    />

                    <Input
                        label="Pris"
                        input={{
                            id: "price",
                            type: "number",
                            onChange: priceChangedHandler,
                            value: enteredPrice,
                            onBlur: priceBlurHandler,
                        }}
                        isValid={!priceInputHasError}
                        errorMessage="Priset behöver vara högre än 0"
                    />

                    <LargeMCEInput onChange={onMceChange} />

                    <InputCarousel
                        images={carouselImages}
                        onChange={onChangeCarousel}
                    />

                    <div>
                        <Button
                            disabled={!formIsValid}
                            type="submit"
                            className={"submit"}
                        >
                            Lägg Till {selectedAnimalType.name}
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default AnimalForm;
