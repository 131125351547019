import React from "react";
import * as classes from "./Select.module.css";

const Select = (props) => {
    return (
        <>
            <div className={classes["select-wrapper"]}>
                <label htmlFor={props.id}>{props.label}</label>
                <select
                    value={props.value}
                    className={classes["select"]}
                    onChange={props.onChange}
                    id={props.id}
                >
                    {props.children}
                </select>
            </div>
        </>
    );
};

export default Select;
