import React, { useState, useEffect } from "react";
import Axios from "../../assets/js/axios-instance";
import AnimalList from "../Animals/AnimalList";
import NewAnimal from "../Animals/NewAnimal";
import * as classes from "./AnimalListPage.module.css";
import { useParams, Routes, Route } from "react-router-dom";
import AnimalDetailGroup from "../Animals/AnimalDetailPage";

const AnimalListPage = () => {
    const params = useParams();
    const [animalType, setAnimalType] = useState(null);
    const [animals, setAnimals] = useState([]);

    const getAnimalTypes = async () => {
        await Axios.get(`/animalType/name/${params.animalTypePluralName}`)
            .then((response) => {
                setAnimalType(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const newAnimalHandler = (animal) => {
        setAnimals((prevAnimals) => {
            return [...prevAnimals, animal];
        });
    };

    useEffect(() => {
        getAnimalTypes();
    }, [params.animalTypePluralName]);

    useEffect(() => {
        if (animalType) {
            setAnimals(animalType.animals);
        }
    }, [animalType]);

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <div className={classes["wrapper"]}>
                            <NewAnimal onNewAnimal={newAnimalHandler} />
                            <AnimalList
                                animalType={animalType}
                                animals={animals}
                            />
                        </div>
                    }
                />
                <Route
                    path=":animalId"
                    element={<AnimalDetailGroup animals={animals} />}
                />
            </Routes>
        </>
    );
};

export default AnimalListPage;
