import React, { useState, useEffect } from "react";
import "./FormTabs.css";
import FormTab from "./FormTab";

const FormTabs = (props) => {
    const [activeTabId, setActiveTabId] = useState("tab__0");

    const handleOnTabClick = (event) => {
        setActiveTabId("tab__" + event.target.id);
    };

    return (
        <>
            <div className={"formTabs"}>
                {props.forms.map((form, key) => {
                    return (
                        <FormTab
                            className={form.props.className}
                            form={form}
                            key={key}
                            id={key}
                            activeTabId={activeTabId}
                            handleOnTabClick={handleOnTabClick}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default FormTabs;
