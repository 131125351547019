import React from "react";
import React, { useContext } from "react";
import AuthContext from "../Context/AuthContext";
import * as classes from "./NavLinks.module.css";
import { Link } from "react-router-dom";
import NavDropdown from "./NavDropdown";

const NavLinks = (props) => {
    const authContext = useContext(AuthContext);

    const styles = props.className
        ? `${props.className} ${classes["navLinks"]}`
        : `${classes["navLinks"]}`;

    return (
        <>
            <ul className={styles}>
                <NavDropdown onLinkClick={props.onClick} label={"Djur"} />
                <li className={classes["navLinks__item"]}>
                    <Link to="/">Hem</Link>
                </li>
                {authContext.isLoggedIn ? (
                    <li
                        onClick={authContext.logout}
                        className={classes["navLinks__item"]}
                    >
                        <Link to="/login">Logga Ut</Link>
                    </li>
                ) : (
                    <li className={classes["navLinks__item"]}>
                        <Link to="/login">Logga In</Link>
                    </li>
                )}
                {/* <NavDropdown onLinkClick={props.onClick} text={"Test"} /> */}
            </ul>
        </>
    );
};

export default NavLinks;
