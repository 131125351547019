import * as classes from "./AnimalTypeList.module.css";
import React from "react";
import Card from "../Ui/Card";

const AnimalTypeList = (props) => {
    return (
        <>
            <ul className={classes["animal-type-list"]}>
                {props.animalTypes.map((animalType) => (
                    <Card
                        key={animalType.id}
                        className={`${classes["animal-type-list__card"]}`}
                    >
                        <li>{animalType.name}</li>
                    </Card>
                ))}
            </ul>
        </>
    );
};

export default AnimalTypeList;
