import React, { useState, useEffect } from "react";
import PostForm from "./PostForm";
import Axios from "../../assets/js/axios-instance";

const CreatePost = (props) => {
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        await Axios.get('/category/all')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getCategories();
    }, []);

    const onPostPostForm = async (postFormData) => {
        await Axios.post('/post/create', postFormData)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    return (
        <>
            <div>
                <PostForm onPost={onPostPostForm} categories={categories} />
            </div>
        </>
    );
};

export default CreatePost;
