import React from "react";
import "./index.css";
import MainHeader from "./components/MainHeader/MainHeader";
import { Route, Routes, Navigate, Link, NavLink } from "react-router-dom";
import PuffPage from "./components/Pages/PuffPage";
import AnimalListPage from "./components/Pages/AnimalListPage";
import AdminPage from "./components/Pages/AdminPage";
import AuthGroup from "./components/Auth.jsx/AuthGroup";
import DeclarationOfInterestForm from "./components/DeclarationOfInterest/DeclarationOfInterestForm";

const App = () => {
    return (
        <div className="main">
            <MainHeader />
            <Routes>
                <Route path="/" element={<PuffPage />} />
                <Route
                    path="djur/:animalTypePluralName/*"
                    element={<AnimalListPage />}
                />
                <Route path="/login" element={<AuthGroup />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/intresse" element={<DeclarationOfInterestForm />} />
            </Routes>
        </div>
    );
};

export default App;
