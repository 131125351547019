.list {
    color: var(--primary-color);
}

.image {
    width: 100%;    
    display: flex;
}

.list-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1em;
    padding: 1em;
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
}