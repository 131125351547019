import React, { useState } from "react";
import Card from "../Ui/Card";
import LoginForm from "./LoginForm";
import Button from "../Buttons/Button";
import SignUpForm from "./SignUpForm";
import * as classes from "./AuthGroup.module.css";

const AuthGroup = (props) => {
    const [isLogin, setIsLogin] = useState(true);

    const onClickSwitchAuth = () => {
        setIsLogin(!isLogin);
    };

    return (
        <>
            <Card className={classes["auth-group"]}>
                <h1 className={classes["header"]}>
                    {isLogin ? "LOGGA IN" : "REGISTRERA"}
                </h1>
                {isLogin ? (
                    <LoginForm />
                ) : (
                    <SignUpForm />
                )}
                <div className={classes["button-wrapper"]}>
                    <Button onClick={onClickSwitchAuth} className="border">
                        {isLogin
                            ? "Skapa nytt konto"
                            : "Logga in med existerande konto"}
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default AuthGroup;
