import * as classes from "./CountryList.module.css";
import React from "react";
import Card from "../Ui/Card";

const CountryList = (props) => {
    return (
        <>
            <ul className={classes["country-list"]}>
                {props.countries.map((country) => (
                    <Card
                        key={country.id}
                        className={`${classes["country-list__card"]}`}
                    >
                        <li>{country.name}</li>
                    </Card>
                ))}
            </ul>
        </>
    );
};

export default CountryList;
