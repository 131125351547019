import React from "react";
import TextPuffForm from "./TextPuffForm";

const NewTextPuff = ({ onAddTextPuff, posts, id, className }) => {
    const saveTextPuffHandler = (puffData) => {
        puffData = {
            ...puffData,
            id: Math.random().toString(),
        };
        onAddTextPuff(puffData);
    };

    return (
        <>
            <TextPuffForm
                onSaveTextPuff={saveTextPuffHandler}
                posts={posts}
                id={id}
                className={className}
            />
        </>
    );
};

export default NewTextPuff;
