.carousel__button {
    width: 100%;
    background-color: #ff9f00;
    color: #292929;
}

.carousel__button:active {
    background-color: #f8c943;
}

.carousel__icon--plus {
    font-size: large;
}
