import * as classes from "./MainHeader.module.css";
import React from "react";
import icon from "../../assets/images/main_icon.png";
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import { Link } from "react-router-dom";

const MainHeader = () => {
    return (
        <>
            <header className={classes["main-header"]}>
                <Link to="/">
                    <div className={classes["main-header__logo"]}>
                        <img
                            className={classes["image"]}
                            src={icon}
                            alt="Bild på Hundar Längtar Hem's ikon"
                        />
                        <h1>HUNDAR LÄNGTAR HEM</h1>
                    </div>
                </Link>
                <MobileNavigation />
                <Navigation />
            </header>
        </>
    );
};

export default MainHeader;
