import React from "react";
import * as classes from "./Navigation.module.css";
import NavLinks from "./NavLinks";
import Button from "../Buttons/Button";
import { useState } from "react";
import { RiMenuLine, RiCloseLine } from "react-icons/ri";

const MobileNavigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onHamburgerClick = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <nav className={classes["mobile-navigation"]}>
                <Button onClick={onHamburgerClick}>
                    {!isOpen && (
                        <RiMenuLine
                            className={classes["mobile-navigation__hamburger"]}
                        />
                    )}
                    {isOpen && (
                        <RiCloseLine
                            className={classes["mobile-navigation__hamburger"]}
                        />
                    )}
                </Button>
            </nav>
            {isOpen && (
                <NavLinks
                    className={classes["mobile-navigation__drop-down"]}
                />
            )}
        </>
    );
};

export default MobileNavigation;
