.nav-dropdown {
    color: white;
    background-color: var(--primary-color);
    font-size: 2em;
    padding: 0.5em;
    align-items: center;
    display: flex;
    position: relative;
    overflow: visible;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.active {
    background-color: var(--primary-hover-color);
}

.dropdown-body {
    background-color: var(--primary-color);
    top: 100%;
    position: absolute;
    right: 0;
    z-index: 20;
}

.dropdown-item {
    cursor: pointer;
    overflow: visible;
    display: flex;
}

.dropdown-item:hover {
    background-color: var(--primary-hover-color);
}

.dropdown-item > a {
    white-space: nowrap;
    color: white;
    padding: 0.5em 1em;
}

@media (max-width: 990px) {
    .nav-dropdown {
        flex-direction: column;
        align-items: start;
    }

    .dropdown-body {
        background-color: var(--primary-hover-color);
        position: static;
    }

    .dropdown-item > a {
        white-space: normal;
    }
}
