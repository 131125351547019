import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./TinyMce.css";
import Axios from "../../assets/js/axios-instance";

export default function LargeTinyMCE(props) {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const uploadImageHandler = async (blobInfo, success, failure) => {
        const fd = new FormData();
        fd.append('file', blobInfo.blob());

        try {
            const { data } = await Axios.post("/TinyMce/upload", fd);
            console.log(data);
        } catch (error) {
            console.log(error.message);
            return;
        }
    };

    return (
        <>
            <Editor
                onInit={(evt, editor) => (
                    (editorRef.current = editor),
                    props.onChange(editor.getContent())
                )}
                onEditorChange={(newValue, editor) => {
                    props.onChange(editor.getContent());
                }}
                apiKey="pzsyo6alyuxeh9kfvy58vha9xctrhqydkt7k1d62zk20lve1"
                init={{
                    height: 500,
                    menubar: false,
                    images_upload_url:
                        "https://api.kimkristiansson.se/api/TinyMce/upload",
                    plugins: [
                        "lists",
                        "advlist",
                        "autolink",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "pagebreak",
                        "code",
                        "emoticons",
                    ],
                    toolbar:
                        "undo redo |" +
                        "numlist bullist |" +
                        "blocks underline bold italic |" +
                        "alignleft aligncenter alignright alignjustify |" +
                        "outdent indent |" +
                        "code image link emoticons",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:18px }",
                    browser_spellcheck: true,
                    contextmenu: false,
                    spellchecker_language: "sv_SE",
                    automatic_uploads: true,
                    document_base_url: "https://api.kimkristiansson.se",
                }}
            />
            <button onClick={log}>Log editor content</button>
        </>
    );
}
