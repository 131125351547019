import React from "react";
import * as classes from "./Button.module.css";

const Button = (props) => {
    return (
        <>
            <button
                className={`${classes.button} ${props.className} ${
                    classes[props.className]
                }`}
                type={props.type || "button"}
                onClick={props.onClick}
                id={props.id}
                disabled={props.disabled}
            >
                {props.children}
            </button>
        </>
    );
};

export default Button;
