import * as classes from "./CategoryGroup.module.css";
import React, { useEffect, useState } from "react";
import Axios from "../../assets/js/axios-instance";
import CategoryList from "./CategoryList";
import Card from "../Ui/Card";
import CategoryForm from "./CategoryForm";

const CategoryGroup = (props) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories();
    }, []);

    const postCategoryHandler = async (enteredName) => {
        Axios.post('/category/create', {
            name: enteredName,
        })
            .then((response) => {
                newCategoryHandler(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const newCategoryHandler = (newCategory) => {
        setCategories((prevCategories) => {
            return [...prevCategories, newCategory];
        });
    };

    const getCategories = async () => {
        await Axios.get('/category/all')
            .then((response) => {
                const data = response.data;
                setCategories(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Card className={classes["category-group"]}>
                <h2 className={classes["header"]}>KATEGORIER</h2>
                <CategoryForm onSubmit={postCategoryHandler} />
                <CategoryList categories={categories} />
            </Card>
        </>
    );
};

export default CategoryGroup;
