import React from "react";
import Puff from "./Puff";
import * as classes from "./Puffs.module.css";

const Puffs = (props) => {
    return (
        <>
            <div className={classes["puffs"]}>
                {props.puffs.map((puff) => (
                    <Puff key={puff.id} puff={puff} />
                ))}
            </div>
        </>
    );
};

export default Puffs;
