.imagePuff__wrapper {
    display: flex;
    position: relative;
}

.imagePuff img {
    width: 100%;
}

.imagePuff button {
    position: absolute;
    bottom:0;
    right: 0;
    margin: 1em
}