import React, { useState, useEffect } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import * as classes from "./NavDropdown.module.css";
import { Link } from "react-router-dom";

const NavDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState();

    useEffect(() => {
        setDropdownStyle(
            isOpen
                ? `${classes["nav-dropdown"]} ${classes["active"]}`
                : `${classes["nav-dropdown"]}`
        );
    }, [isOpen]);

    const onClickDropdownHandler = () => {
        setIsOpen(!isOpen);
    };

    const onMouseEnterHandler = () => {
        setIsOpen(true);
    };

    const onMouseLeaveHandler = () => {
        setIsOpen(false);
    };

    return (
        <>
            <li
                onClick={onClickDropdownHandler}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                className={dropdownStyle}
            >
                <div>
                    {props.label}
                    {isOpen ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
                </div>
                {isOpen && (
                    <ul className={classes["dropdown-body"]}>
                        <li
                            onClick={props.onLinkClick}
                            className={classes["dropdown-item"]}
                        >
                            <Link to="djur/hundar">Hundar</Link>
                        </li>
                        <li
                            onClick={props.onLinkClick}
                            className={classes["dropdown-item"]}
                        >
                            <Link to="djur/katter">Katter</Link>
                        </li>
                    </ul>
                )}
            </li>
        </>
    );
};
export default NavDropdown;
