import React from "react";
import ImagePuffForm from "./ImagePuffForm";

const NewImagePuff = ({ onAddImagePuff, posts, id, className }) => {
    const saveImagePuffHandler = (puffData) => {
        puffData = {
            ...puffData,
            id: Math.random().toString(),
        };
        onAddImagePuff(puffData);
    };

    return (
        <>
            <ImagePuffForm
                onSaveImagePuff={saveImagePuffHandler}
                posts={posts}
                id={id}
                className={className}
            />
        </>
        );
};

export default NewImagePuff;
