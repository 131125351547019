import React from "react";
import * as classes from "./Form.module.css";

const Form = (props) => {
    return (
        <>
            <form
                className={`${classes["form"]} ${props.className}`}
                onSubmit={props.onSubmit}
                encType={props.encType}
            >
                {props.children}
            </form>
        </>
    );
};

export default Form;
