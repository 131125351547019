import React from "react";
import * as styles from "./Input.module.css";

const Input = (props) => {
    const inputClasses = props.isValid
        ? `${styles.input}`
        : `${styles.input} ${styles.invalid}`;

    return (
        <>
            <div className={inputClasses}>
                <label ref={props.labelRef} htmlFor={props.input.id}>
                    {props.label}
                </label>
                <input {...props.input} />
                {!props.isValid && <p className={styles['invalid-message']}>{props.errorMessage}</p>}
            </div>
        </>
    );
};

export default Input;
