.button {
    border: none;
    padding: 1em;
    font-size: 1rem;
    border-radius: var(--primary-border-radius);
    background-color: transparent;
    font-weight: bold;
}

.button:hover {
    cursor: pointer;
}

.button:disabled {
    background: #dddddd !important; 
}

.button.green {
    background-color: var(--approve-color);
    color: white;
}

.button.red {
    background-color: var(--danger-color);
    color: white;
}

.button.submit {
    background-color: var(--primary-color);
    color: white;
}

.button.forestGreen {
    background-color: #2c5f2d;
    color: white;
}

.button.pacificCoast {
    background-color: #5b84b1;
    color: white;
}

.button.princessBlue {
    background-color: #4a83b7;
    color: white;
}

.button.orange {
    background-color: #fbde8d;
    color:#292929;
}

.button.border{
    border: 1px solid var(--primary-color);
    padding: 1em;
    font-size: 1rem;
    border-radius: var(--primary-border-radius);
    background-color: var(--primary-background-color);
    color: var(--primary-color);
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
    background-color: #ccc;
    color: #292929;
    border-color: #ccc;
    cursor: not-allowed
}