.input-container input[type="file"] {
    display: none;
}

.input-container {
    display: flex;
    flex-wrap: wrap;
}

.label {
    font-weight: 600;
    margin-bottom: 0.5em;
    flex-basis: 100%;
}

.options {
    overflow: hidden;
    display: flex;
    flex-basis: 100%;
    color: black;
    background-color: white;
    border-radius: var(--primary-border-radius);
    border: 2px dashed #ffe5b4;
    font-weight: 600;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
}

.input-wrapper {
    position: relative;
}

.input-wrapper label {
    cursor: pointer;
    display: flex;
}

.input-wrapper img {
    max-height: 20vh;
    border-radius: var(--primary-border-radius);
}

.input-icon {
    position: absolute;
    color: lightgray;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem;
}

.button {
    display: flex;
    background-color: #DC3545;
    padding: .5em;
    font-size: 1.5rem;
    color: white;
}

.button-icon {
    pointer-events: none
}