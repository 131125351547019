import * as classes from "./Navigation.module.css";
import React from "react";
import NavLinks from "./NavLinks";

const Navigation = () => {
    return (
        <>
            <nav className={classes["navigation"]}>
                <NavLinks />
            </nav>
        </>
    );
};

export default Navigation;
