import React, { useState, useContext } from "react";
import Form from "../Ui/Form";
import useInput from "../Hooks/useInput";
import Input from "../Inputs/Input";
import Button from "../Buttons/Button";
import Axios from "../../assets/js/axios-instance";
import AuthContext from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const LoginForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    const isAboveTwoCharacters = (value) => value.trim().length >= 2;
    const isAboveSixCharacters = (value) => value.trim().length >= 6;

    const {
        value: enteredUsername,
        isValid: enteredUsernameIsValid,
        hasError: usernameInputHasError,
        valueChangeHandler: usernameChangedHandler,
        inputBlurHandler: usernameBlurHandler,
        reset: resetUsernameInput,
    } = useInput(isAboveTwoCharacters);

    const {
        value: enteredPassword,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPasswordInput,
    } = useInput(isAboveSixCharacters);

    let formIsValid = false;

    if (enteredUsernameIsValid && enteredPasswordIsValid) {
        formIsValid = true;
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        const loginData = {
            username: enteredUsername,
            password: enteredPassword,
        };

        setIsLoading(true);
        await Axios.post('/user/authenticate', loginData)
            .then((response) => {
                const data = response.data;
                authContext.login(data.token, data.id);
                navigate("/");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Form onSubmit={submitHandler}>
                <Input
                    label="Användarnamn"
                    input={{
                        id: "usernameInput",
                        type: "text",
                        onChange: usernameChangedHandler,
                        value: enteredUsername,
                        onBlur: usernameBlurHandler,
                    }}
                    isValid={!usernameInputHasError}
                    errorMessage="Användarnamnet behöver innehålla minst 2 bokstäver"
                />

                <Input
                    label="Lösenord"
                    input={{
                        id: "passwordInput",
                        type: "password",
                        onChange: passwordChangedHandler,
                        value: enteredPassword,
                        onBlur: passwordBlurHandler,
                    }}
                    isValid={!passwordInputHasError}
                    errorMessage="Lösenordet behöver innehålla minst 6 tecken"
                />

                <Button
                    disabled={!formIsValid}
                    type="submit"
                    className={"green"}
                >
                    Logga In
                </Button>
            </Form>
        </>
    );
};

export default LoginForm;
