import React from "react";
import Button from "../../Buttons/Button";
import Card from "../../Ui/Card";
import * as classes from "./ImagePuff.module.css";

const ImagePuff = (props) => {
    return (
        <>
            <Card className={classes["imagePuff"]}>
                <div className={classes["imagePuff__wrapper"]}>
                    <img src={props.imagePuff.image} alt="" />
                    <Button className="border">
                            {props.imagePuff.buttonText}
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default ImagePuff;
