.navLinks {
    display: flex;
    height: 100%;
    overflow: visible;
}

.navLinksitem {
    display: flex;
    flex-direction: column;
}

.navLinks__item:hover {
    background-color: var(--primary-hover-color);
}


.navLinks__item > a,
.navLinks__item > button {
    height: 100%;
    color: white;
    padding: 0;
    font-size: 2em;
    flex-basis: 100%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
