import Input from "../Inputs/Input";
import Form from "../Ui/Form";
import Button from "../Buttons/Button";
import useInput from "../Hooks/useInput";

const CategortyForm = (props) => {
    const isAboveTwoCharacters = (value) => value.trim().length >= 2;

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput,
    } = useInput(isAboveTwoCharacters);

    let formIsValid = false;

    if (enteredNameIsValid) {
        formIsValid = true;
    }

    const onSubmissionHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        props.onSubmit(enteredName);

        resetNameInput();
    };

    return (
        <>
            <Form onSubmit={onSubmissionHandler}>
                <Input
                    label="Namn"
                    input={{
                        id: "categoryName",
                        type: "text",
                        onChange: nameChangedHandler,
                        value: enteredName,
                        onBlur: nameBlurHandler,
                    }}
                    isValid={!nameInputHasError}
                    errorMessage="Namnet behöver innehålla minst 2 bokstäver"
                />
                <Button
                    disabled={!formIsValid}
                    type="submit"
                    className={"green"}
                >
                    Lägg Till Kategori
                </Button>
            </Form>
        </>
    );
};

export default CategortyForm;
