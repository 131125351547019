.carousel {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.carousel__label {
    padding-bottom: .5em;
    font-weight: 600;
    display: block;
}
 
.carousel__image {
    border-radius: var(--primary-border-radius);
    max-height: 100vh;
    display: none;
}
 
.carousel__arrow {
    position: absolute;
    top: 43%;
    font-size: 3rem;
    color: black;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    opacity: 50%;
}
 
.carousel__arrow--right {
    right: 1%;
}
 
.carousel__arrow--left {
    left: 1%;
}
 
.carousel__image.carousel__image--active {
    opacity: 1;
    transition-duration: 1s ease;
    display: block;
}
